export function contents() {

  function matchHeights() {
    $('.atc-info-wrap').matchHeight();
    $('.atc-title').matchHeight();
  }

  function AddSpcls(){
    let sp = window.matchMedia('(max-width: 767px)').matches;
      let pc = window.matchMedia('(min-width: 768px)').matches;
      if(sp){
        $('.category-tabs__item').addClass('category-tab-sp');
        $('.category-tabs-wrap').css('display', 'none');
      } else if(pc){
        $('.category-tabs__item').removeClass('category-tab-sp');
        $('.category-tabs-wrap').css('display', 'block');
      }
  }

  function topicsTabAction(){
    $(document).on('click', '.category-tab-sp', function(){
      let cateName = $(this).text();
      $('.category-btn__txt').text(cateName);
      $('.category-tabs-wrap').slideUp();
    });
    $('.js-category-tabs__item').on('click', function(){
      let cateName = $(this).text();
      $('.category-btn__txt').text(cateName);
    })
    $('.js-category-btn').on('click', function(){
      $('.category-tabs-wrap').slideToggle();
    });
  }

  function atcImages() {
    $('.atc-image-wrap__img').each(function(){
      let atcImgWid = $(this).width();
      let atcBordWid = $('.contents').width(); 
      let atcImgPer = atcImgWid / atcBordWid * 100 + "%";
      if (atcBordWid > atcImgWid){
        $(this).css('width', 'auto');
      } else if(atcBordWid < atcImgWid){
        $(this).css({
          'width': '100%',
          'max-width': atcImgWid
        });
      }
    });
  }

  function figImg() {
    $('.atc-image-wrap__fig').each(function(){
      let figImg = $(this).find('img').outerWidth();
      $(this).css('max-width', figImg);
    });
  }

  function modal(){
    $('.modal-open').on('click', function(){
      $(this).next('.modal').fadeIn();
      $('html').addClass('modalset');
    });
    $('.modal .modal-bg,.modal .modal-close').on('click', function(){
      $('.modal').fadeOut();
      $('html').removeClass('modalset');
    });
    $(document).on('click', '.modal .modal-bg,.modal .modal-close', function(){
      $('button.vjs-play-control.vjs-playing').trigger('click');
      videoControl("pauseVideo");
      function videoControl(action){
        let youtubeCls = $('.modal-youtube__inner');
        for(let i = 0;i < youtubeCls.length;i++){
          let $playerWindow = $('.modal-youtube__inner')[i].contentWindow;
          $playerWindow.postMessage('{"event":"command","func":"'+action+'","args":""}', '*');
        }
      }
    })
  }

  // movieのauth処理
  function modalPass() {
    $('.js-movie-pass-btn').on('click', function(){

      // 対象動画
      var target = $(this).parents('.modal-pass-box');
      var input_pass = target.find('.modal-pass-input').val();
      var input_movie_id = target.find('.modal-movie_id-input').val();

      //
      $.ajax({
        url      : '/movies/auth',
        data     : {
          passwd : input_pass,
          movie_id : input_movie_id,
        },
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        },
        type     : 'POST',
      }).done(function(json){

        if(json.response == true){
          var video_tag = json.data.video_html;
          video_tag = video_tag.replace('<iframe','<iframe class="modal-youtube__inner" ')

          target.parents('.modal-box').find('.modal-youtube').html(video_tag);
          target.remove();
        }

      }).fail(function(json){
        console.log(json);
      });

    });
  }

  function borderEff() {
    let secTitle = $('.sec-ttl__inner');
    let animated = 'is-border';
    
    secTitle.each(function(){
    
      let titleOffset = $(this).offset().top;
      let scrollPos = $(window).scrollTop();
      let wh = $(window).height();

      if(scrollPos > titleOffset - wh + 200 ){
        $(this).addClass(animated);
      }
    });
  }

  function appearEff() {
    let vanishEl = $('.is-vanished');
    let appear = 'is-appear';
    
    vanishEl.each(function(){
      let vanishElOffset = $(this).offset().top;
      let scrollPos = $(window).scrollTop();
      let wh = $(window).height();

      if(scrollPos > vanishElOffset - wh + 150 ){
        $(this).addClass(appear);
      }
    });
  }

  function youtubeControlAdd() {
    $('.modal-youtube__inner').each(function(){
      let ytSrc = $(this).attr('src');
      $(this).attr('src', ytSrc + '?enablejsapi=1');
    });  
  }

  function topicsSortSp() {
    let tpcPara = $(location).attr('search');
    if(tpcPara === '?topics_category_id=1') {
      $('.category-btn__txt').text('ニュースリリース');
    } else if(tpcPara === '?topics_category_id=2') {
      $('.category-btn__txt').text('お知らせ');
    } else if(tpcPara === '?topics_category_id=3'){
      $('.category-btn__txt').text('REACH REACHからのお知らせ');
    } else {
      $('.category-btn__txt').text('すべて');
    }
  }

  
  $(function(){
    topicsTabAction();
    //matchHeights();
    modal();
    modalPass();
    youtubeControlAdd();
    topicsSortSp();
  });

  $(window).on('load', function(){
    //figImg();
  });

  $(window).on('load resize', function(){
    AddSpcls();
    //atcImages();
  });

  $(window).on('load scroll', function(){
    borderEff();
    appearEff();
  });
  
};


