import { jQuery } from './vendor/jquery-3.6.0.min.js';
import { slick } from './vendor/slick.min.js';
import { matchHeight } from './vendor/jquery.matchHeight.js';
import { adaptiveBg } from './vendor/jquery.adaptive-backgrounds.js';
import { browserSelector } from './vendor/css_browser_selector.js';
import { header_footer } from './header_footer.js';
import { contents } from './contents.js';
jQuery();
slick();
matchHeight();
//browserSelector();
adaptiveBg();
header_footer();
contents();



