export function header_footer() {
  
  function navBtnAction() {
    $('.js-header__sp-nav-bar').on('click', function(){
      if(!($(this).hasClass('is-open'))) {
        $(this).addClass('is-open').removeClass('is-close');
        $(this).children().addClass('is-open').removeClass('is-close');
        $(this).next('.header__nav-wrap').addClass('is-open').removeClass('is-close');
        $('html').addClass('is-view-lock');
      } else if($(this).hasClass('is-open')) {
        $(this).removeClass('is-open').addClass('is-close');
        $(this).children().removeClass('is-open').addClass('is-close');
        $(this).next('.header__nav-wrap').removeClass('is-open').addClass('is-close');
        $('html').removeClass('is-view-lock');
      }
    });
  }

  function scrollLogoAction() {
    const wTop = $(window).scrollTop();
    const logoR = $('.header__logo-wrap');
    const menu = $('.js-header__sp-nav-bar');
    const menuApp = $('.js-menu-app');
    const contents = menuApp.offset().top;
    if(contents < wTop) {
      logoR.addClass('is-scrolling');
      menu.addClass('is-scrolling');
    } else {
      logoR.removeClass('is-scrolling');
      menu.removeClass('is-scrolling');
    }
  }
  
  $(function(){
    navBtnAction();
  });
  
  $(window).on('load', function(){
    //scrollLogoAction()
  })

  $(window).on('scroll', function(){
    //scrollLogoAction()
  })

  

};


